import React from 'react';

import Layout from '../components/layout';
import { Row, Col, Heading, Frame, Blockquote, Line } from 'arwes';

import Loading from '../components/loading';

class Education extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        });
    }
    
    render() {
        if (this.state.loading) {
            return (<Loading />);
        }

        return (<Layout>
            <div style={{ maxWidth: "400px", paddingBottom: "40px" }}>
                <Frame animate layer='primary' corners={4}>
                    {anim => (
                        <div style={{paddingTop: "10px"}}>
                            <Heading animate show={anim.entered} node='h1'>Education</Heading>
                        </div>
                    )}
                </Frame>
            </div>

            <div style={{ padding: "20px" }}>
                <Heading node='h3'>Harvard University</Heading>
                <Heading node='h5'>B.A. Biomedical Engineering and Computer Science</Heading>
                <Heading node='h6'>Minor in Mandarin Chinese</Heading>
                
                <Line layer="control" />
                <Row>
                    <Col s={6}>
                        <Heading node='h6'>Biomedical Engineering</Heading>
                    </Col>
                    <Col s={6}>
                        <Heading node='h6'>Computer Science</Heading>
                    </Col>
                </Row>

                <Row>
                    <Col s={6}>
                        <Blockquote style={{ fontSize: "16px" }}>
                            <ul>
                                <li>Multivariate Calculus</li>
                                <li>Anatomy and Human Physiology</li>
                                <li>Linear Signals Processing and Analysis</li>
                                <li>Organic Chemistry</li>
                                <li>Intro to Statistical Mechanics</li>
                                <li>Physics: Mechanics with Special Relativity</li>
                                <li>Physics: Electricity and Magnetism</li>
                                <li>Cellular Engineering (grad course)</li>
                            </ul>
                        </Blockquote>
                    </Col>
                    <Col s={6}>
                        <Blockquote style={{ fontSize: "16px" }}>
                            <ul>
                                <li>Linear Algebra</li>
                                <li>Functional Programming (OCaml) and Recursion</li>
                                <li>Intro to Theoretical CS (with Quantum Algorithms)</li>
                                <li>Algorithms and Data Structures</li>
                                <li>Intro to C and Systems</li>
                                <li>Economics, Game Theory, and Computation</li>
                                <li>Big Data Systems (grad course)</li>
                                <li>Systems Security (grad course)</li>
                            </ul>
                        </Blockquote>
                    </Col>
                </Row>
                <Line layer="control" />
                
            </div>
        </Layout>);
    }
}

export default Education;